import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DefaultResponse } from './domains/calculator.domain';
import { of, Subject } from 'rxjs';
import { utils } from '../utils/utils';
import { ProductividadReport, ProductividadRoutes } from '../pages/productividad/productividad.domain';

@Injectable({
  providedIn: 'root'
})
export class ProductividadService {

  constructor(private httpC: HttpClient) { }

  reloadFilters = new Subject<boolean>();

  getProductivityFilters() {
    return this.httpC.get<DefaultResponse<{ id: number, name: string, urlReport: string }[]>>(environment.platformApi + 'cultivation/productivity/dashboard');
  }

  getReportResourceByUrl(urlRes: string, optionalParams?: { [key: string]: any | string | number | undefined }) {
    const idUser = this.getOldUserId();
    let params = new HttpParams()
      .set('idProd', this.getClientProducerSelected().oldProducerID)
      .set('idUser', idUser)
    if (optionalParams) {
      for (const key in optionalParams) {
        if (!optionalParams[key]) continue
        params = params.append(key, optionalParams[key])
      }
    }
    return this.httpC.get<any>(`${environment.productivityApiUrl}/${urlRes}`, { params: params })
  }

  getProductivityReports() {
    return PRODUCTIVITY_REPORTS
  }

  private getClientProducerSelected() {
    return utils.getClientProducerSelected();
  }

  private getOldUserId() {
    return utils.getOldUserId() ?? 921;
  }
}

const PRODUCTIVITY_REPORTS: ProductividadReport[] = [/* {
  id: 1,
  name: 'Reporte alarmas lotes',
  urlReport: ProductividadRoutes.REPORTE_ALARMA_LOTES
},
{
  id: 2,
  name: 'Reporte alarmas malezas',
  urlReport: ProductividadRoutes.REPORTE_ALARMA_MALEZA
}, */
  {
    id: 3,
    name: 'Control de Cosecha',
    urlReport: ProductividadRoutes.CONTROL_COSECHA
  },
  {
    id: 4,
    name: 'Comparativa Ndvi',
    urlReport: ProductividadRoutes.COMPARATIVA_NDVI
  },
  {
    id: 5,
    name: 'Reporte EIQ',
    urlReport: ProductividadRoutes.REPORTE_EIQ
  },
  {
  id: 10,
  name: 'Freatimetros',
  urlReport: ProductividadRoutes.FREATIMETROS
},
{
    id: 21,
    name: 'Cultivo Soja Prodeman',
    urlReport: ProductividadRoutes.CULTIVO_PRODEMAN_SOJA
  },
  {
    id: 22,
    name: 'Cultivo Mani Prodeman',
    urlReport: ProductividadRoutes.CULTIVO_PRODEMAN_MANI
  },
  {
    id: 23,
    name: 'Cultivo Maiz Prodeman',
    urlReport: ProductividadRoutes.CULTIVO_PRODEMAN_MAIZ
  },
  {
    id: 24,
    name: 'Avance de Cosecha',
    urlReport: ProductividadRoutes.AVANCE_COSECHA
  }
]





//TODO: may refactor
export interface ProductivityReport {
  id: number;
  name: string;
  filters: Filter[];
  graphs?: Graph[];
  providers?: Provider[]
  route?: string;
}

export interface Provider {
  name: string;
  url: string;
  description: string | null;
}

export interface Filter {
  id: number;
  ref?: any;
  name: string;
  optionLabel?: string;
  type: string;
  paramRef?: string;
  datasetURL?: string | null;
  values?: any;
  currentValue?: any;
  dependences?: number[];
  order?: FilterOrder;
  placeHolder?: string;
}

export interface FilterOrder {
  group: number,
  order: number
}

export interface FilterValue {
  id: number;
  value: number | string;
}

export interface Graph {
  id?: number;
  name?: string;
  type?: string;
  datasetURL?: string;
  data?: Data;
  widthPercent?: number;
}

export interface Data {
  pathRef?: string;
  labels: any[];
  datasets: any[];
}

export interface Label {
  id: number;
  value: string;
}

export interface DataValue {
  id: number;
  value: number;
}
