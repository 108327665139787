import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ApiCatchsInterceptor } from './services/interceptors/api-catchs.interceptor';
import { TokenInterceptor } from './services/interceptors/token.interceptor';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { DataformModule } from './components/dataform/dataform.module';
import { PremiumModule } from './components/premium/premium.module';
import { DividerModule } from 'primeng/divider';
import { TooltipModule } from 'primeng/tooltip';
import { PatchNotesComponent } from './components/patch-notes/patch-notes.component';
import { MigrandoModule } from './components/migrando/migrando.module';
import { ToastModule } from 'primeng/toast';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouterReuseStrategy } from './custom-router-reuse.strategy';
import { EditorModule } from 'primeng/editor';
import { DatePipe } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    DropdownModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FontAwesomeModule,
    FormsModule,
    AvatarModule,
    AvatarGroupModule,
    ProgressSpinnerModule,
    DialogModule,
    TableModule,
    DataformModule,
    PremiumModule,
    DividerModule,
    TooltipModule,
    PatchNotesComponent,
    MigrandoModule,
    ToastModule,
    ReactiveFormsModule,
    EditorModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiCatchsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouterReuseStrategy,
    },
    DatePipe
    // Otros servicios/providers
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab, far);
  }
}
