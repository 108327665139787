export enum ProductividadRoutes {
    REPORTE_ALARMA_LOTES = 'alarma-lotes',
    REPORTE_ALARMA_MALEZA = 'alarma-maleza',
    REPORTE_EIQ = 'reporte-eiq',
    CONTROL_COSECHA = 'control-cosecha',
    COMPARATIVA_NDVI = 'comparativa-ndvi',
    CULTIVO_PRODEMAN = 'cultivo-prodeman/:cultivo',
    CULTIVO_PRODEMAN_SOJA = 'cultivo-prodeman/soja',
    CULTIVO_PRODEMAN_MANI = 'cultivo-prodeman/mani',
    CULTIVO_PRODEMAN_MAIZ = 'cultivo-prodeman/maiz',
    AVANCE_COSECHA = 'avance-cosecha',
    FREATIMETROS = 'freatimetros',
}

export interface ProductividadReport {
    id: number, name: string, urlReport: string
}

export enum ProductividadFiltersType{
    CAMPAIGN = 'CAMPAIGN',
    CULTIVO = 'CULTIVO',
    TIPO_CULTIVO = 'TIPO-CULTIVO',
    ZONA = 'ZONA',
    SUBZONA = 'SUBZONA',
    CAMPO = 'CAMPO',
    CAMPO_LOTE = 'CAMPO-LOTE',
    LOTE = 'LOTE'
}